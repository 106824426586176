/* Fonts */
@font-face {
  font-family: "VisbyCF-Bold";
  src: url("./fonts/VisbyCF-Bold.eot");
  src: url("./fonts/VisbyCF-Bold.eot") format("embedded-opentype"),
  url("./fonts/VisbyCF-Bold.woff") format("woff"),
  url("./fonts/VisbyCF-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "VisbyCF";
  src: url("./fonts/VisbyCF-Medium.eot");
  src: url("./fonts/VisbyCF-Medium.eot") format("embedded-opentype"),
  url("./fonts/VisbyCF-Medium.woff") format("woff"),
  url("./fonts/VisbyCF-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Ubuntu";
  src: url("./fonts/Ubuntu-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

body.no-overflow {
  overflow: hidden;
  position: relative;
  height: 100%;
  touch-action: none;
  -ms-touch-action: none;
}

strong {
  font-family: 'VisbyCF-Bold';
}

b {
  font-family: 'VisbyCF-Bold';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.thumbs {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
}

.thumbs img {
  max-width: 45px;
}

/* Form Styles  */

form.add-form {
  display: flex;
  flex-wrap: wrap;
  padding: 0 90px;
}

form.add-form.edit-player {
  display: block;
}

.modal-form form.add-form {
  padding: 0;
}

.modal-form form.add-form .left-side {
  max-width: initial;
  margin-right: initial;
  margin-bottom: initial;
}

.modal-form form.add-form .right-side {
  max-width: initial;
  margin-bottom: initial;
}

.modal-form form.add-form .form-controls {
  margin-top: 24px;
}

@media (max-width: 767px) {
  form.add-form {
    padding: 0;
  }
}

form.add-form .MuiTextField-root {
  min-width: 200px;
  margin-bottom: 10px;
}

form.add-form .right-side {
  max-width: 32%;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  form.add-form .right-side {
    max-width: 100%;
  }
}

form.add-form .left-side {
  max-width: 60%;
  margin-right: 34px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  form.add-form .left-side {
    max-width: 100%;
    margin-right: 0;
  }
}

form.add-form .form-controls {
  width: 100%;
  text-align: right;
}

form.add-form .form-controls button {
  margin-left: 17px;
}

.facebook-login-button {
  width: 40px;
  height: 40px;
  font-size: 26px;
  border: 0;
  background-color: #3b5998;
  color: #FFFFFF;
  border-radius: 4px;
  opacity: 1;
  padding: 0 6px;
}

.MuiMenu-paper .MuiList-root {
  max-height: 400px;
}

.MuiTypography-root.MuiTypography-caption {
  font-family: 'VisbyCF-Bold';
  font-size: 0.875rem;
}

.MuiAvatar-root.MuiAvatar-rounded {
  height: auto;
  background: #F1f1f1;
}

.MuiAvatar-root.MuiAvatar-square {
  height: auto;
  background: #F1f1f1;
}

.MuiAvatar-root.MuiAvatar-rounded .MuiAvatar-img {
  object-fit: contain;
}

.MuiAvatar-root.MuiAvatar-square .MuiAvatar-img {
  object-fit: contain;
}

strong {
  font-family: 'VisbyCF-Bold';
}

b {
  font-family: 'VisbyCF-Bold';
}

.MuiAutocomplete-option .view-more-link {
  color: #e43a00;
  width: 100%;
  text-align: center;
}
